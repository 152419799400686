import TasksFeedback from "../views/feedback";
import SocialSignUp from "../views/socialSignup";
import ConfirmEmail from "../views/verifyEmail";
import WelcomePage from "../views/welcomePage";
import SignUp from "../views/auth";
import DownloadApp from "../views/downloadApp";

const routes = [
    {
        id: 1,
        path: '/feedback/:uid/:lg?',
        component: TasksFeedback,
        isPublic: true,
    },
    {
        id: 2,
        path: '/tasks-Feedback',
        component: TasksFeedback,
        isPublic: true,
    },
      {
        id: 3,
          path: '/social-sign-up/:uid',
        component: SocialSignUp,
        isPublic: true,
    },
    {
        id: 4,
        path: '/confirm-email',
        component: ConfirmEmail,
        isPublic: true,
    },
    {
        id: 5,
        path: '/welcome/:uid?/:fid?',
        component: WelcomePage,
        isPublic: true,
    },
    {
        id: 6,
        path: '/sign-up/:uid',
        component: SignUp,
        isPublic: true,
    },
    {
        id: 7,
        path: '/',
        component: WelcomePage,
        isPublic: true,
    },
    {
        id: 8,
        path: '/download-app',
        component: DownloadApp,
        isPublic: true,
    },
]
export default routes;