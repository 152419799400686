import secureLocalStorage from "react-secure-storage";

global.translatedContent = null;

export async function getTranslations() {
    let portalLanguages = [];

    await fetch(window.location.origin + '/config.json')
        .then((r) => r.json())
        .then((data) => {
            portalLanguages = data.portalLanguages
        });

    getLocalTranslations(portalLanguages);
}

function getLocalTranslations(portalLanguages) {
    var objLang = new Object();
    for (const pLang of portalLanguages) {
        let key = pLang.substring(0, 2);
        objLang[key] = require('../languages/' + pLang.substring(0, 2) + '.json');
    }
    global.translatedContent = objLang;

    secureLocalStorage.setItem("translatedContent", JSON.stringify(global.translatedContent));
}