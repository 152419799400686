import React, { useEffect, useState } from "react";
import styles from "../auth/styles.module.scss";
import { Card, CardHeader, CardBody, Form, Container, Col, Row, Button } from "reactstrap";
import classnames from 'classnames';
import appStore from '../../images/app_store.svg';
import playStore from '../../images/play_store.png';
import { getDocument } from '../../services/helperFirebase';
import { createorUpdateDocument, getDocumentByUserId, createDocument, sendPushNotification } from '../../services/helperFirebase';
import loading from '../../images/loading.gif';
import helper from '../../services/helper';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function DownloadApp(props) {
    const { content } = props;
    const [appStoreUrl, setAppStoreUrl] = useState('');
    const [playStoreUrl, setPlayStoreUrl] = useState('');


    useEffect(() => {
        fetch(window.location.origin + '/config.json')
            .then((r) => r.json())
            .then((data) => {
                setAppStoreUrl(data.appStoreUrl);
                setPlayStoreUrl(data.playStoreUrl);
            });
    }, []);

    const downloadApp = () => {
        if ((navigator.platform.indexOf("iPhone") !== -1) || (navigator.platform.indexOf("iPod") !== -1) || (navigator.platform.indexOf("iPad") !== -1) || navigator.platform.toLowerCase().indexOf("macintel") !== -1) {
            window.open(appStoreUrl); 
        } else {
            window.open(playStoreUrl); 
        }


    };

    return (

        <div>
            <div className="wrapper wrapper-full-page">
                <div className={classnames("full-page section-image", styles.welcomeFullPage)}>
                    <div className="login-page">
                        <Container>
                            <Row >
                                <Col className={classnames("ml-auto mr-auto", styles.col)} lg="5" md="6">
                                    <Form action="" className="form" method="">
                                        <Card className={classnames("card-login", styles.loginPage)}>
                                            <CardHeader>
                                                <h3 className={classnames("header text-center mb-0", styles.cardHeaderText)}>{content.WELCOME_TO_PREEE}</h3>
                                            </CardHeader>
                                            <CardBody className={styles.welcomeCardBody}>
                                                <div className={styles.cardBodyText}>
                                                    {content.DOWNLOAD_THE_APP_AND_JOIN_YOUR_FRIEND}
                                                </div>
                                                <br />
                                                <br />

                                                <Button
                                                    className={classnames(styles.downloadApp)}
                                                    color="warning"
                                                    onClick={downloadApp}
                                                >
                                                    {content.DOWNLOAD_APP}
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>

        </div>
    )
}


const mapStateToProps = (state) => {

    return {
        content: state.languageReducer.portalContent,
        languageCode: state.languageReducer.languageCode,

    };
};
export default connect(mapStateToProps)(withRouter(DownloadApp));