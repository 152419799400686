import { auth } from '../services/firebase-config'
import { storage, db } from './firebase-config';
import {
    GoogleAuthProvider,
    signInWithPopup,
    OAuthProvider,
} from 'firebase/auth';
import {
    ref,
    getDownloadURL,
} from 'firebase/storage';
import {
    setDoc,
    getDoc,
    doc,
    addDoc,
    collection,
    query,
    getDocs,
    where,
} from "firebase/firestore";

export async function createorUpdateDocument(tableName, documentId, data) {
    let obj = {};
    if (Boolean(documentId)) {
        try {
            const docRef = doc(db, tableName, documentId);
            await setDoc(docRef, data).then(() => {
                obj = {
                    message: "Document successfully written!",
                    statusCode: 200,
                }
            });
        } catch (error) {
            obj = {
                message: `Error while setting document : ${error}`,
                statusCode: 400,
            }
        }
    } else {
        obj = {
            message: `document id is missing`,
            statusCode: 400,
        }
    }
    return obj;
}

export async function createDocument(tableName, data) {
    let obj = {};
    try {
        await addDoc(collection(db, tableName), data).then(() => {
            obj = {
                message: "Document successfully written!",
                statusCode: 200,
            }
        });
    } catch (error) {
        obj = {
            message: `Error while setting document : ${error}`,
            statusCode: 400,
        }
    }
    return obj;
}

export async function getDocumentByUserId(tableName, userId, friendId) {
    let documentRef = query(collection(db, tableName), where("userId", "==", userId));
    var isFriands = false
    const querySnapshot = await getDocs(documentRef);
    querySnapshot.forEach((doc) => {
        var docData = doc.data()
        if (docData.friendId === friendId) {
            isFriands = true
        }
    });
    return isFriands;
}

export async function getDocument(tableName, documentID) {
    let documentRef = doc(db, tableName, documentID);

    const docSnap = await getDoc(documentRef);

    const data = docSnap.exists() ? docSnap.data() : null;

    if (data === null || data === undefined) return false;

    return { ...data };
}


export const signUpWithGoogle = async () => {
    try {
        const googleProvider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;
        return user
    } catch (error) {
        console.error('Error signing in with Google:', error.message);
    }
};

export const signUpWithApple = async () => {
    try {
        const appleProvider = new OAuthProvider('apple.com');
        console.log("appleProvider", appleProvider)
        const result = await signInWithPopup(auth, appleProvider);
        const user = result.user;
        return user;
    } catch (error) {
        console.error('Error signing in with Apple:', error.message);
    }
};

export async function downloadFile(path, name) {
    const fileRef = ref(storage, `${path}${name}`);
    var result;

    try {
        await getDownloadURL(fileRef).then(url => {
            result = url;
        });
    } catch (error) {
        result = false;
    }
    return result;
}



export const sendPushNotification = async (fcmToken, topic, title, body, isSilent) => {
    if (fcmToken !== undefined && fcmToken !== null && fcmToken !== '') {
        let message = {
            registration_ids: fcmToken,
            data: {
                title: title,
                body: body,
                topic: topic,
            },
            apns: {
                payload: {
                    aps: {
                        badge: 0,
                    },
                },
            },
            priority: "high",
            content_available: true
        };
        // console.log("FCM payload:", JSON.stringify(message))
        let requestUrl = "https://fcm.googleapis.com/fcm/send"
        let headers = new Headers({
            "Content-Type": "application/json",
            Authorization: "key=AAAAJ9fit9Y:APA91bHd2V7Blg7WvolMIiP1ZbGJ293vW1xyKb5XpRc8oMlT944Ek8lCGszPH12Ygxl97QeHix9caCbR1xENX74BB_JuDUIK5awI9zvJraL0jKAlpXCTN2PAmhjXsfmSJIy_g-vXc4FI",
        });

        try {
            let response = await fetch(requestUrl, {
                method: "POST",
                headers,
                body: JSON.stringify(message),
            });
            response = await response.json();
        } catch (e) {
            console.log("Error Notification:", e)
        }
    }
}