import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import Routing from './router/routing';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/scss/preee-theme.scss?v=1.3.0";
import './assets/demo/demo.css';
import { Provider } from 'react-redux';
import configureStore from './redux/store/configureStore';
import { getTranslations } from '../src/services/languageTranslation';
import { useEffect } from 'react';

const store = configureStore(window.__State__);

function App() {

    useEffect(() => {
        getTranslations();
    })

    return (
        <div className="App">
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Routing />
                    </Switch>
                </Router>
            </Provider>
        </div>
    );
}

export default App;
