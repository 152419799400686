import React, { useEffect, useState } from "react";
import styles from "../auth/styles.module.scss";
import { Button, Card, CardHeader, CardBody, Form, Container, Col, Row } from "reactstrap";
import classnames from 'classnames';
import { useHistory, withRouter } from "react-router-dom";
import { getDocument, downloadFile } from "../../services/helperFirebase";
import axios from 'axios';
import avatar from '../../images/avatar.png';
import { getPortalLanguage } from "../../redux/actions/action";
import { connect } from "react-redux";
import loading from '../../images/loading.gif';
import secureLocalStorage from "react-secure-storage";

let languages = [];
function TasksFeedback(props) {
    const { content } = props;
    const [userName, setUserName] = useState('');
    const [userImage, setUserImage] = useState('');
    const [shortLink, setShortLink] = useState('');
    const [portalLanguageValue, setPortalLanguageValue] = useState();
    const [userData, setUserData] = useState(true);
    const [loadingStatus, setLoadingStatus] = useState(true);

    const languageName = props.match.params['lg']
    let alreadyCreated = 0;

    //getLocalTranslations(portalLanguages);
    useEffect(() => {
        if (global.translatedContent != null) {
            const { getPortalLanguage } = props;
            secureLocalStorage.setItem("languageCode", languageName);

            var objLanguage = new Object();
            objLanguage.languageCode = languageName;

            getPortalLanguage(objLanguage);
            setLoadingStatus(false)
        }
    }, [global.translatedContent]);
    const history = useHistory();
    const paramsUserId = props.match.params['uid']
    async function handleSignUp() {
        history.push('/social-sign-up/' + paramsUserId);
    }

    useEffect(() => {
        fetch(window.location.origin + '/config.json')
            .then((r) => r.json())
            .then((data) => {
                generateDynamicLink(data.firebaseApikey, data.domainUriPrefix, data.preeeDomain, data.preeeImageLink);
            });
        checkUserId(props.match.params['uid']);
    }, []);


    async function getUserData(userId) {
        let data = await getDocument('Users', userId);
        let userName = data.name
        setUserName(userName);
        let userImage = await downloadFile(`/profilePictures/`, userId);

        if (userImage !== false) {
            setUserImage(userImage);
        }
        else {
            setUserImage(avatar);
        }
    }

    async function checkUserId(userId) {
        let data = await getDocument('Users', userId);
        alreadyCreated++
        if (data === false && alreadyCreated == 1) {
            setUserData(false)
            alert("Invalid URL")
            getUserData(userId)
        } else {
            getUserData(userId)         
        }
    }

    async function generateDynamicLink(firebaseApikey, domainUriPrefix, preeeDomain, preeeImageLink) {
       
        let body = {
            dynamicLinkInfo: {
                domainUriPrefix: domainUriPrefix,
                link: preeeDomain +"?userId="+ paramsUserId,
                androidInfo: {
                    androidPackageName: "com.preee.app",
                },
                iosInfo: {
                    iosBundleId: "com.preee.app",
                    iosAppStoreId: "6475332296",
                    iosCustomScheme: "preee",
                },
                navigationInfo: {
                    enableForcedRedirect: false,
                },
                socialMetaTagInfo: {
                    socialTitle: "Preee: Daily Task Focus",
                    socialDescription: "A transformative tool in daily task management, designed to help you navigate your day with precision and purpose",
                    socialImageLink: preeeImageLink
                }
            },
        };
        await axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseApikey}`, body)
            .then(function (response) {
                let shortLink = response.data.shortLink
                //console.log(shortLink)
                setShortLink(shortLink)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    async function handleOnClick() {
        window.location.href = shortLink
    }

    return (

        <div>
            {loadingStatus ? <Row className={styles.loaderRow}>
                <img src={loading} className={styles.loader} alt="loader" />
            </Row>
                :
                <div className="wrapper wrapper-full-page">
                    <div className={classnames("full-page section-image", styles.fullPage)}>
                        <div className="login-page">
                            <Container>
                                <Row >
                                    <Col className={classnames("ml-auto mr-auto", styles.col)} lg="5" md="6">
                                        <Form action="" className="form" method="">
                                            <Card className={classnames("card-login", styles.loginPage)}>
                                                <CardHeader>
                                                    <div className={styles.divImage}>
                                                        <img src={userImage} className={styles.img} >
                                                        </img>
                                                    </div>
                                                    <CardHeader>
                                                        <h3 className={classnames("header text-center mb-0", styles.name)}>{userName}</h3>
                                                        <p className={classnames("header text-center", styles.content)}>{content.YOUR_BREDREN} <span className={styles.contentName} >{userName}</span> {content.NEEDS_YOUR_HELP} <br />{content.AND_FEEDBACK_IN_COMPLETING}  <br />
                                                            {content.EVERYDAY_TASKS}</p>
                                                    </CardHeader>
                                                </CardHeader>
                                                <CardBody className={styles.feedbackCardBody}>


                                                    <Button
                                                        block
                                                        className={classnames("btn-round mb-3", styles.signUpAccount)}
                                                        color="warning"
                                                        onClick={handleSignUp}
                                                        disabled={!userData}
                                                    >
                                                        {content.SIGN_UP}

                                                    </Button>
                                                    <Button
                                                        block
                                                        className={classnames("btn-round mb-3", styles.signUp)}
                                                        color="warning"
                                                        onClick={handleOnClick}
                                                        disabled={!userData}
                                                    >
                                                        {content.I_ALREADY_HAVE_AN_ACCOUNT}
                                                    </Button>

                                                </CardBody>
                                            </Card>
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
const mapStateToProps = (state) => {

    return {
        content: state.languageReducer.portalContent,
        languageCode: state.languageReducer.languageCode,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPortalLanguage: (data) => dispatch(getPortalLanguage(data)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TasksFeedback));