import React, { useEffect, useState } from "react";
import styles from "../auth/styles.module.scss";
import { Card, CardHeader, CardBody, Form, Container, Col, Row } from "reactstrap";
import classnames from 'classnames';
import appStore from '../../images/app_store.svg';
import playStore from '../../images/play_store.png';
import { getDocument } from '../../services/helperFirebase';
import { createorUpdateDocument, getDocumentByUserId, createDocument, sendPushNotification } from '../../services/helperFirebase';
import loading from '../../images/loading.gif';
import helper from '../../services/helper';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
function WelcomePage(props) {
    const { content } = props;
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [appStoreUrl, setAppStoreUrl] = useState('');
    const [playStoreUrl, setPlayStoreUrl] = useState('');
    let alreadyCreated = 0;

    useEffect(() => {
        fetch(window.location.origin + '/config.json')
            .then((r) => r.json())
            .then((data) => {
                setAppStoreUrl(data.appStoreUrl);
                setPlayStoreUrl(data.playStoreUrl);
            });
        Boolean(props.match.params["uid"]) && userDataUpdate(props.match.params['uid']);
    }, []);

    const paramsFriendId = props.match.params["fid"]
    async function userDataUpdate(userId) {
        setLoadingStatus(true)
        let userData = await getDocument('Users', userId);
        if (Boolean(userData)) {

            let obj = {
                userId: userData.userId,
                appVersion: userData.appVersion,
                email: userData.email,
                name: userData.name,
                isActive: true,
                isDeleted: userData.isDeleted,
                isVerified: true,
                createdAt: userData.createdAt,
                updatedAt: moment(moment()).toISOString()
            };

            let result = await createorUpdateDocument('Users', userId, obj);
            const notificationsObj = {
                        "userId": userData.userId,
                        "appNotification": true,
                        "chat": {
                            "digestEmail": true,
                            "mentioned": true,
                            "newMessage": true,
                            "notification": true
                        },
                        "conversation": true,                       
                        "dailyReview": {
                            "notification": true,
                            "notificationTime": {
                                "hour": 21,
                                "minute": 0,
                                "selectedDays": [
                                    0,
                                    1,
                                    2,
                                    3,
                                    4,
                                    5,
                                    6
                                ],
                                "time": moment(moment()).toISOString()
                            }
                        },
                        "dailyTaskReminder": {
                            "notification": true,
                            "notificationTime": {
                                "hour": 9,
                                "minute": 0,
                                "selectedDays": [
                                    0,
                                    1,
                                    2,
                                    3,
                                    4,
                                    5,
                                    6
                                ],
                                "time": moment(moment()).toISOString()
                            }
                        },
                        "eveningPreeeReview": true,
                        "morningPreeeSetup": true,
                        "motivation": true,
                        "myFriends": {
                            "completedTasks": true,
                            "messageFriends": true,
                            "newTasks": true,
                            "notification": true
                        },
                        "updatedAt": moment(moment()).toISOString(),
                        "createdAt": moment(moment()).toISOString(),                      
                    }
            await createorUpdateDocument('Notifications', userData.userId, notificationsObj);
            try {

                const friendsObj = {
                    userId: paramsFriendId,
                    friendId: userData.userId,
                    friendName: userData.name,
                    isActive: true,
                    createdAt: moment(moment()).toISOString(),
                    updatedAt: moment(moment()).toISOString()
                }
                let data = await getDocumentByUserId('Friends', paramsFriendId, userData.userId);
                let friendData = await getDocumentByUserId('Friends', userData.userId, paramsFriendId);

                let getUserData = await getDocument('Users', paramsFriendId);
                let fcmToken = getUserData.fcmToken

                let getUserName = await getDocument('Users', userData.userId);
                let userName = getUserName.name

                alreadyCreated++
                if (data != true && friendData != true && alreadyCreated == 1) {
                    await createDocument('Friends', friendsObj);

                    sendPushNotification([fcmToken],
                        "friend_join",
                        userName + " has accepted your invitation",
                        false)

                    const obj = {
                        userId: userId,
                        type: "friend_join",
                        userName: userName,
                        message: userName + " has accepted your invitation",
                        createdAt: moment(moment()).toISOString(),
                        updatedAt: moment(moment()).toISOString()
                    }

                    let data = await getDocument('NotificationHistory', paramsFriendId);

                    if (data) {
                        data.list.push(obj)
                        await createorUpdateDocument('NotificationHistory', paramsFriendId, data);
                    } else {
                        let dataFriends = { list: [obj] }
                        await createorUpdateDocument('NotificationHistory', paramsFriendId, dataFriends);
                    }
                }
            } catch (error) {
                console.error('Error occurred:', error);
            }

            if (result.statusCode === 200) {
                setLoadingStatus(false)
            }
        }
    }

    return (

        <div>
            {loadingStatus ?
                <Row className={styles.loaderRow}>
                    <img src={loading} className={styles.loader} alt="loader" />
                </Row> :
                <div className="wrapper wrapper-full-page">
                    <div className={classnames("full-page section-image", styles.welcomeFullPage)}>
                        <div className="login-page">
                            <Container>
                                <Row >
                                    <Col className={classnames("ml-auto mr-auto", styles.col)} lg="5" md="6">
                                        <Form action="" className="form" method="">
                                            <Card className={classnames("card-login", styles.loginPage)}>
                                                <CardHeader>
                                                    <h3 className={classnames("header text-center mb-0", styles.cardHeaderText)}>{content.WELCOME_TO_PREEE}</h3>
                                                </CardHeader>
                                                <CardBody className={styles.welcomeCardBody}>
                                                    <div className={styles.cardBodyText}>
                                                        {content.CONGRATULATIONS}
                                                        <br />
                                                        {content.DOWNLOAD_THE_APP_AND_JOIN_YOUR_FRIEND}
                                                    </div>
                                                    <a href={appStoreUrl} target="_blank">
                                                        <img src={appStore} className={styles.appStoreImg} />
                                                    </a>

                                                    <br />
                                                    <a href={playStoreUrl} target="_blank">
                                                        <img src={playStore} className={styles.playStoreImg} />
                                                    </a>
                                                </CardBody>
                                            </Card>
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}


const mapStateToProps = (state) => {

    return {
        content: state.languageReducer.portalContent,
        languageCode: state.languageReducer.languageCode,

    };
};
export default connect(mapStateToProps)(withRouter(WelcomePage));