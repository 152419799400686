import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import classnames from 'classnames';
import { Collapse, NavbarBrand, Navbar, NavItem, Nav, Container } from "reactstrap";
import styles from "./styles.module.scss";
import preee from '../../images/preee.png'
function Layout({ children }) {
    return (
        <div id="container">

            <Navbar className={classnames("navbar-absolute fixed-top",styles.navbar)} expand="lg">
                <Container className={styles.header}>
                    <img src={preee} className={styles.preeeImg } />
                </Container>
            </Navbar>
            <div className="main-content">
                <div className="public-content">
                    {children}
                </div>
            </div>
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.object.isRequired,
};

export default Layout;
