import React, { useState } from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { Button, Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container } from "reactstrap";
import secureLocalStorage from "react-secure-storage";
function Header(props) {
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [color, setColor] = React.useState("navbar-transparent");
    const location = useLocation();
    React.useEffect(() => {
        window.addEventListener("resize", updateColor);
    });

    React.useEffect(() => {
        if (window.outerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
            document.documentElement.classList.toggle("nav-open");
        };
    }, [location]);

    const updateColor = () => {
        if (window.innerWidth < 993 && collapseOpen) {
            setColor("bg-white");
        } else {
            setColor("navbar-transparent");
        }
    };

    const toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        setSidebarOpen(!sidebarOpen);
    };

    const toggleCollapse = () => {
        if (!collapseOpen) {
            setColor("bg-white");
        } else {
            setColor("navbar-transparent");
        }
        setCollapseOpen(!collapseOpen);
    };

    
      const logout = () => {
          secureLocalStorage.clear()
          window.location = '/';
      };

    return (
        <Navbar className={classnames(color)} expand="lg">
            <Container fluid>
                <div className="navbar-wrapper">
                    <div className="navbar-minimize">
                        <Button
                            className="btn-icon btn-round"
                            color="default"
                            id="minimizeSidebar"
                            onClick={props.handleMiniClick}>
                            <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                            <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                        </Button>
                    </div>
                    <div
                        className={classnames("navbar-toggle", {
                            toggled: sidebarOpen,
                        })}
                    >
                        <button className="navbar-toggler" type="button" onClick={toggleSidebar} >
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                </div>
                <button
                    aria-controls="navigation-index"
                    aria-expanded={collapseOpen}
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    // data-target="#navigation"
                    data-toggle="collapse"
                    onClick={toggleCollapse}
                    style={{ backgroundColor: "transparent" }}
                >
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                </button>



                <Collapse className="justify-content-end" navbar isOpen={collapseOpen}>
                    <div className={styles.logout}>
                        <Button onClick={logout} color="warning" className={classnames("btn-round")}>
                            <span className="btn-label mr-2">
                                <i className="fa fa-lock" />
                            </span>
                            Logout
                        </Button>
                    </div>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
