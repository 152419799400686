import styles from "../auth/styles.module.scss";
import { Card, CardHeader, CardBody, Form, Container, Col, Row } from "reactstrap";
import classnames from 'classnames';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
function ConfirmEmail(props) {

    const { content } = props;
    const { email } = props.location.state;

    return (

        <div>
            <div className="wrapper wrapper-full-page">
                <div className={classnames("full-page section-image", styles.ConfirmFullPage)}>
                    <div className="login-page">
                        <Container>
                            <Row >
                                <Col className={classnames("ml-auto mr-auto", styles.col)} lg="5" md="6">
                                    <Form action="" className="form" method="">
                                        <Card className={classnames("card-login", styles.loginPage)}>
                                            <CardHeader>
                                                <h3 className={classnames("header text-center mb-0", styles.cardHeaderText)}>{content.CONFIRM_EMAIL}</h3>
                                                </CardHeader>
                                            <CardBody className={styles.verifyCardBody}>
                                                <div className={styles.cardBodyText}>
                                                    {content.EMAIL_WITH_A_CONFIRMATION} 
                                                </div>
                                                <div className={styles.cardBodyText}>
                                                    {content.SENT_TO_THE} <b>{email}</b>
                                                </div>
                                                <div className={styles.cardBodyText}>
                                                    {content.EMAIL_ADDRESS} 
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {

    return {
        content: state.languageReducer.portalContent,
        languageCode: state.languageReducer.languageCode,

    };
};
export default connect(mapStateToProps)(withRouter(ConfirmEmail));