import React, { useState, useEffect } from "react";
import styles from "../auth/styles.module.scss";
import { Button, Card, CardHeader, CardBody, CardFooter, FormGroup, Form, Input, InputGroupText, InputGroup, Container, Col, Row } from "reactstrap";
import classnames from 'classnames';
import apple from '../../images/Apple.svg'
import google from '../../images/Google.svg'
import { useHistory, withRouter } from "react-router-dom";

import { signUpWithGoogle, createorUpdateDocument, signUpWithApple, getDocument, createDocument, getDocumentByUserId, sendPushNotification } from '../../services/helperFirebase';
import helper from '../../services/helper'
import { connect } from "react-redux";
import moment from 'moment';
function SocialSignUp(props) {
    const history = useHistory();
    const paramsUserId = props.match.params['uid']
    const [userData, setUserData] = useState(true);
    const { content } = props;
    let alreadyCreated = 0;

    let typeOfTask = [
        {
            id: '1',
            category: "work",
            selected: true,
            color: "#FC2769"
        },
        {
            id: '2',
            category: "Finances",
            selected: false,
            color: "#29D5AB"
        },
        {
            id: '3',
            category: "Relationships",
            selected: false,
            color: "#229BDF"
        },
        {
            id: '4',
            category: "Personal Growth",
            selected: false,
            color: "#2047F9"
        },
        {
            id: '5',
            category: "Mindfulness",
            selected: false,
            color: "#FC613E"
        },
        {
            id: '6',
            category: "Health",
            selected: false,
            color: "#BF5AFF"
        }
    ];

    useEffect(() => {
        checkUserId(paramsUserId);
    }, []);
    async function checkUserId(paramsUserId) {
        let data = await getDocument('Users', paramsUserId);
        alreadyCreated++
        if (data === false && alreadyCreated == 1) {
            setUserData(false)
            alert("Invalid URL")
        }
    }

    const handleSignUpWithGoogle = async () => {
        try {
            signUpWithGoogle().then(async (response) => {              

                if (response !== undefined) {
                    let obj = {
                        userId: response.uid,
                        appVersion: "1.0.0",
                        email: response.email,
                        isActive: true,
                        isDeleted: false,
                        isVerified: true,
                        language: "en",
                        country: "asia",
                        typeOfTask: typeOfTask,
                        name: response.displayName,
                        createdAt: moment(moment()).toISOString(),
                        updatedAt: moment(moment()).toISOString()
                    }
                    await createorUpdateDocument('Users', response.uid, obj);

                    const notificationsObj = {
                        "userId": response.uid,
                        "appNotification": true,
                        "chat": {
                            "digestEmail": true,
                            "mentioned": true,
                            "newMessage": true,
                            "notification": true
                        },
                        "conversation": true,                       
                        "dailyReview": {
                            "notification": true,
                            "notificationTime": {
                                "hour": 21,
                                "minute": 0,
                                "selectedDays": [
                                    0,
                                    1,
                                    2,
                                    3,
                                    4,
                                    5,
                                    6
                                ],
                                "time": moment(moment()).toISOString()
                            }
                        },
                        "dailyTaskReminder": {
                            "notification": true,
                            "notificationTime": {
                                "hour": 9,
                                "minute": 0,
                                "selectedDays": [
                                    0,
                                    1,
                                    2,
                                    3,
                                    4,
                                    5,
                                    6
                                ],
                                "time": moment(moment()).toISOString()
                            }
                        },
                        "eveningPreeeReview": true,
                        "morningPreeeSetup": true,
                        "motivation": true,
                        "myFriends": {
                            "completedTasks": true,
                            "messageFriends": true,
                            "newTasks": true,
                            "notification": true
                        },
                        "updatedAt": moment(moment()).toISOString(),
                        "createdAt": moment(moment()).toISOString(),                      
                    }
                    await createorUpdateDocument('Notifications', response.uid, notificationsObj);
                    try {
                        const friendsObj = {
                            userId: paramsUserId,
                            friendId: response.uid,
                            friendName: response.displayName,
                            isActive: true,
                            createdAt: moment(moment()).toISOString(),
                            updatedAt: moment(moment()).toISOString()
                        }

                        let data = await getDocumentByUserId('Friends', paramsUserId, response.uid);
                        let friendData = await getDocumentByUserId('Friends', response.uid, paramsUserId);

                        let getUserData = await getDocument('Users', paramsUserId);
                        let fcmToken = getUserData.fcmToken

                        const displayName = response.displayName !== null && response.displayName !== undefined &&
                            response.displayName !== "" ? response.displayName : 'Pree user';

                        if (data != true && friendData != true) {
                            await createDocument('Friends', friendsObj);

                            sendPushNotification([fcmToken],
                                "friend_join",
                                displayName + " has accepted your invitation",
                                false)

                            const obj = {
                                userId: response.uid,
                                type: "friend_join",
                                userName: displayName,
                                message: displayName + " has accepted your invitation",
                                createdAt: moment(moment()).toISOString(),
                                updatedAt: moment(moment()).toISOString()
                            }

                            let data = await getDocument('NotificationHistory', paramsUserId);

                            if (data) {
                                data.list.push(obj)
                                await createorUpdateDocument('NotificationHistory', paramsUserId, data);
                            } else {
                                let dataFriends = { list: [obj] }
                                await createorUpdateDocument('NotificationHistory', paramsUserId, dataFriends);
                            }
                        }

                        history.push('/welcome');
                    } catch (error) {
                        console.error('Error occurred:', error);
                    }
                }
            })
        }
        catch (error) {
            console.log(error)
        }
    }
    const handleSignUpWithApple = async () => {
        try {
            signUpWithApple().then(async (response) => {
                if (response !== undefined) {
                    let obj = {
                        userId: response.uid,
                        appVersion: "1.0.0",
                        email: response.email,
                        isActive: true,
                        isDeleted: false,
                        isVerified: true,
                        language: "en",
                        country: "asia",
                        typeOfTask: typeOfTask,
                        name: response.displayName,
                        createdAt: moment(moment()).toISOString(),
                        updatedAt: moment(moment()).toISOString()
                    }
                    
                    createorUpdateDocument('Users', response.uid, obj);
                    const notificationsObj = {
                        "userId": response.uid,
                        "appNotification": true,
                        "chat": {
                            "digestEmail": true,
                            "mentioned": true,
                            "newMessage": true,
                            "notification": true
                        },
                        "conversation": true,
                        "dailyReview": {
                            "notification": true,
                            "notificationTime": {
                                "hour": 21,
                                "minute": 0,
                                "selectedDays": [
                                    0,
                                    1,
                                    2,
                                    3,
                                    4,
                                    5,
                                    6
                                ],
                                "time": moment(moment()).toISOString()
                            }
                        },
                        "dailyTaskReminder": {
                            "notification": true,
                            "notificationTime": {
                                "hour": 9,
                                "minute": 0,
                                "selectedDays": [
                                    0,
                                    1,
                                    2,
                                    3,
                                    4,
                                    5,
                                    6
                                ],
                                "time": moment(moment()).toISOString()
                            }
                        },
                        "eveningPreeeReview": true,
                        "morningPreeeSetup": true,
                        "motivation": true,
                        "myFriends": {
                            "completedTasks": true,
                            "messageFriends": true,
                            "newTasks": true,
                            "notification": true
                        },
                        "updatedAt": moment(moment()).toISOString(),
                        "createdAt": moment(moment()).toISOString(),
                    }
                    await createorUpdateDocument('Notifications', response.uid, notificationsObj);

                    try {
                        const friendsObj = {
                            userId: paramsUserId,
                            friendId: response.uid,
                            friendName: response.displayName,
                            isActive: true,
                            createdAt: moment(moment()).toISOString(),
                            updatedAt: moment(moment()).toISOString()
                        }

                        let data = await getDocumentByUserId('Friends', paramsUserId, response.uid);
                        let friendData = await getDocumentByUserId('Friends', response.uid, paramsUserId);

                        let getUserData = await getDocument('Users', paramsUserId);
                        let fcmToken = getUserData.fcmToken
                        const displayName = response.displayName !== null && response.displayName !== undefined &&
                            response.displayName !== "" ? response.displayName : 'Pree user';

                        if (data != true && friendData != true) {
                            await createDocument('Friends', friendsObj);

                            sendPushNotification([fcmToken],
                                "friend_join",
                                displayName + " has accepted your invitation",
                                false)

                            const obj = {
                                userId: response.uid,
                                type: "friend_join",
                                userName: displayName,
                                message: displayName + " has accepted your invitation",
                                createdAt: moment(moment()).toISOString(),
                                updatedAt: moment(moment()).toISOString()
                            }

                            let data = await getDocument('NotificationHistory', paramsUserId);

                            if (data) {
                                data.list.push(obj)
                                await createorUpdateDocument('NotificationHistory', paramsUserId, data);
                            } else {
                                let dataFriends = { list: [obj] }
                                await createorUpdateDocument('NotificationHistory', paramsUserId, dataFriends);
                            }
                        }

                        history.push('/welcome');

                    } catch (error) {
                        console.error('Error occurred:', error);
                    }
                }
            })
        } catch (error) {
            console.log(error)
        }
    };
    async function handleSignUpWithEmail() {
        history.push('/sign-up/' + paramsUserId);
    }
    return (

        <div>
            <div className="wrapper wrapper-full-page">
                <div className={classnames("full-page section-image", styles.socialFullPage)}>
                    <div className="login-page">
                        <Container>
                            <Row >
                                <Col className={classnames("ml-auto mr-auto", styles.col)} lg="5" md="6">
                                    <Form action="" className="form" method="">
                                        <Card className={classnames("card-login", styles.loginPage)}>
                                            <CardHeader>
                                                <div className={styles.divImage}>

                                                    <h3 className={classnames("header text-center mb-0", styles.name)}>{content.SIGN_UP}</h3>
                                                    <p className={classnames("header text-center", styles.content)}>{content.SIGN_UP_FOR_THE_PREEE_APP}</p>
                                                </div>

                                            </CardHeader>
                                            <CardBody className={styles.cardBody}>
                                                <Button
                                                    block
                                                    className={classnames("btn-round mb-3", styles.signUpAccount)}
                                                    color="warning"
                                                    onClick={handleSignUpWithApple}
                                                    disabled={!userData}
                                                >
                                                    <img
                                                        src={apple}
                                                        className={styles.socialIcon}
                                                    />
                                                    {content.SIGN_UP_WITH_APPLE}
                                                </Button>

                                                <Button
                                                    block
                                                    className={classnames("btn-round mb-3", styles.signUpAccount)}
                                                    color="warning"
                                                    onClick={handleSignUpWithGoogle}
                                                    disabled={!userData}
                                                >
                                                    <img
                                                        src={google}
                                                        className={styles.socialIcon}
                                                    />
                                                    {content.SIGN_UP_WITH_GOOGLE}
                                                </Button>

                                                <div className={styles.verticalLine}>
                                                    <hr className={styles.hr} />
                                                    <div className={styles.text}>{content.OR_YOUR_EMAIL}</div>
                                                </div>
                                                <Button
                                                    block
                                                    className={classnames("btn-round mb-3", styles.signUpWithEmail)}
                                                    color="warning"
                                                    onClick={handleSignUpWithEmail}
                                                    disabled={!userData}
                                                >
                                                    {content.SIGN_UP_WITH_EMAIL}
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {

    return {
        content: state.languageReducer.portalContent,
        languageCode: state.languageReducer.languageCode,

    };
};
export default connect(mapStateToProps)(withRouter(SocialSignUp));
