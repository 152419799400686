import React from "react";
import Header from "../header";
import styles from "./styles.module.scss";
import { withRouter } from "react-router-dom";
//import Sidebar from "../sidebar";
import routes from "../../router/routes";

class LandingLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeColor: "info",
            backgroundColor: "black",
            sidebarMini: false,
        };
    }

    handleActiveClick = (color) => {
        this.setState({ activeColor: color });
    };
    handleBgClick = (color) => {
        this.setState({ backgroundColor: color });
    };

    handleMiniClick = () => {
        if (document.body.classList.contains("sidebar-mini")) {
            this.setState({ sidebarMini: false });
        } else {
            this.setState({ sidebarMini: true });
        }
        document.body.classList.toggle("sidebar-mini");
    };



    render() {
        const { children, ...rest } = this.props;
        const { backgroundColor, activeColor } = this.state;
        const filterRoutes = routes.filter((route) => !route.isPublic && route.isInSidebar);

        return (
            <div id="landing-container" className={styles.layoutWrapper}>
                <div className={styles.mainContent}>
                    {/*<div className="main-panel">
                        <Header logout={this.logout} {...rest} handleMiniClick={this.handleMiniClick} />
                    </div>
                    <div className="center-content">
                        <Sidebar {...rest} routes={filterRoutes} bgColor={backgroundColor} activeColor={activeColor} />
                        <div className="main-panel">{children} </div>
                    </div>*/}
                </div>
            </div>
        );
    }
}

export default withRouter(LandingLayout);
