import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Button, Card, CardHeader, CardBody, CardFooter, FormGroup, Form, Input, InputGroupText, InputGroup, Container, Col, Row, } from "reactstrap";
import classnames from 'classnames';
import { withRouter } from "react-router-dom";
import { createorUpdateDocument, getDocument } from '../../services/helperFirebase'
import { auth } from "../../services/firebase-config";
import { createUserWithEmailAndPassword, sendEmailVerification, sendSignInLinkToEmail, } from "firebase/auth";
import helper from '../../services/helper';
import { connect } from "react-redux";
import moment from 'moment';
import eye from "../../images/eye.svg";
import hide from "../../images/hide.svg";
function SignUp(props) {

    const { content } = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [preeeDomain, setPreeeDomain] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(false);
    const paramsUserId = props.match.params['uid']
    const [userData, setUserData] = useState(true);
    const [isPasswordShown, setPasswordShown] = useState(false);
    const [isConfirmPasswordShown, setConfirmPasswordShown] = useState(false);
    let alreadyCreated = 0;

    useEffect(() => {
        fetch(window.location.origin + '/config.json')
            .then((r) => r.json())
            .then((data) => {
                setPreeeDomain(data.preeeDomain);
            });
        checkUserId(paramsUserId);
    }, []);


    async function checkUserId(paramsUserId) {
        let data = await getDocument('Users', paramsUserId);
        alreadyCreated++
        if (data === false && alreadyCreated == 1) {
            setUserData(false)
            alert("Invalid URL")
        }
    }

    async function handleLogin() {
       
        let isValid = validateInputs();

        if (isValid) {
            setLoadingStatus(true)
            try {
                const response = await createUserWithEmailAndPassword(auth, email, password)
                let user = response.user;
                let typeOfTask = [
                    {
                        id: '1',
                        category: "work",
                        selected: true,
                        color: "#FC2769"
                    },
                    {
                        id: '2',
                        category: "Finances",
                        selected: false,
                        color: "#29D5AB"
                    },
                    {
                        id: '3',
                        category: "Relationships",
                        selected: false,
                        color: "#229BDF"
                    },
                    {
                        id: '4',
                        category: "Personal Growth",
                        selected: false,
                        color: "#2047F9"
                    },
                    {
                        id: '5',
                        category: "Mindfulness",
                        selected: false,
                        color: "#FC613E"
                    },
                    {
                        id: '6',
                        category: "Health",
                        selected: false,
                        color: "#BF5AFF"
                    }
                ];

                let obj = {
                    userId: response?.user?.uid,
                    appVersion: "1.0.0",
                    email: email,
                    name: userName,
                    isDeleted: false,
                    typeOfTask: typeOfTask,
                    createdAt: moment(moment()).toISOString(),
                    updatedAt: moment(moment()).toISOString()
                }
                let result = await createorUpdateDocument('Users', response?.user?.uid, obj);
                if (result.statusCode === 200) {
                    const continueUrl = preeeDomain + `/welcome/${user?.uid}/` + paramsUserId;
                    const actionCodeSettings = {
                        url: auth.currentUser.emailVerified ? undefined : continueUrl,
                        handleCodeInApp: true,
                    };

                    sendEmailVerification(auth.currentUser, actionCodeSettings)
                        .then(() => {
                            console.log("Email verification sent!")
                        }).catch((error) => {
                            console.error(error.message)
                        });
                    setLoadingStatus(false);
                    props.history.push({
                        pathname: "/confirm-email",
                        state: { email: email }
                    });
                }
            } catch (error) {
                if (error.code === 'auth/email-already-in-use') {
                    alert(content.EMAIL_IS_ALREADY_REGISTERED);
                    setLoadingStatus(false);
                } else {
                    console.error("Registration error:", error.message);
                }
            }
        }
    }

    function validateInputs() {
        let messages = '';

        if (userName.trim() === "") {
            messages += content.PLEASE_ENTER_USERNAME + "\n";
        }

        if (email.trim() === "") {
            messages += content.PLEASE_ENTER_EMAIL + "\n";
        } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            messages += content.PLEASE_ENTER_VALID_EMAIL + '\n';
        }

        if (password.trim() === "") {
            messages += content.PLEASE_ENTER_PASSWORD + "\n";
        } else if (password.trim().length < 8) {
            messages += content.PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS_LONG + "\n";
        }

        if (confirmPassword.trim() === "") {
            messages += content.PLEASE_ENTER_CONFIRM_PASSWORD + "\n";
        }

        if (password !== confirmPassword) {
            messages += content.BOTH_THE_PASSWORDS_MUST_MATCH + '\n';
        }

        if (messages.trim().length > 0) {
            alert(messages);
            return false;
        }
        else
            return true;
    }

    //For fetching user details 

    //For handling login on enter key press
    const handleKeypress = e => {
        if (e.keyCode === 13) {
            handleLogin();
        }
    };

    return (

        <div>
            <div className="wrapper wrapper-full-page">
                <div className={classnames("full-page section-image", styles.signUpFullPage)}>
                    <div className="login-page">
                        <Container>
                            <Row >
                                <Col className={classnames("ml-auto mr-auto", styles.col)} lg="5" md="6">
                                    <Form onSubmit={handleLogin} action="" className="form" method="">
                                        <Card className={classnames("card-login", styles.loginPage)}>
                                            <CardHeader>
                                                <h3 className={classnames("header text-center mb-0", styles.cardHeaderText)}>{content.SIGN_UP_WITH_EMAIL}</h3>
                                            </CardHeader>
                                            <CardBody className={styles.signUpCardBody}>
                                                <FormGroup row>
                                                    <Col>
                                                        <Input
                                                            className={styles.inputBox}
                                                            type="text"
                                                            placeholder={content.USER_NAME}
                                                            value={userName}
                                                            onChange={(e) => setUserName(e.target.value)}
                                                            onKeyUp={(e) => handleKeypress(e)}
                                                            data-cy="email"
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col>
                                                        <Input
                                                            className={styles.inputBox}
                                                            type="email"
                                                            placeholder={content.EMAIL}
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            onKeyUp={(e) => handleKeypress(e)}
                                                            data-cy="email"
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <InputGroup>
                                                    <Input
                                                        className={styles.inputBox}
                                                        placeholder={content.PASSWORD}
                                                        type={isPasswordShown ? "text" : "password"}
                                                        autoComplete="off"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        onKeyUp={(e) => handleKeypress(e)}
                                                        data-cy="password"
                                                        maxlength="20"
                                                    />
                                                    <InputGroupText className={styles.visibilityIcon}>
                                                        <img style={{ width: "20px" }}
                                                            onClick={() => setPasswordShown(!isPasswordShown)} src={!isPasswordShown ? hide : eye} alt="password visibility icon" />
                                                    </InputGroupText>
                                                </InputGroup>

                                                <InputGroup>
                                                    <Input
                                                        className={styles.inputBox}
                                                        placeholder={content.CONFIRM_PASSWORD}
                                                        type={isConfirmPasswordShown ? "text" : "password"}
                                                        autoComplete="off"
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                        onKeyUp={(e) => handleKeypress(e)}
                                                        data-cy="password"
                                                        maxlength="20"
                                                    />
                                                    <InputGroupText className={styles.visibilityIcon}>
                                                        <img style={{ width: "20px" }}
                                                            onClick={() => setConfirmPasswordShown(!isConfirmPasswordShown)} src={!isConfirmPasswordShown ? hide : eye} alt="password visibility icon" />
                                                    </InputGroupText>

                                                </InputGroup>
                                            </CardBody>
                                            <CardFooter className={styles.cardFooter}>
                                                <Button
                                                    id="signUp"
                                                    block
                                                    className={classnames("btn-round mb-3", styles.signUpAccount)}
                                                    disabled={loadingStatus}
                                                    //color="warning"
                                                    onClick={handleLogin}
                                                    disabled={!userData}
                                                >
                                                    <a>
                                                        {
                                                            loadingStatus ?
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                :
                                                                content.SIGN_UP
                                                        }
                                                    </a>

                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {

    return {
        content: state.languageReducer.portalContent,
        languageCode: state.languageReducer.languageCode,
    };
};
export default connect(mapStateToProps)(withRouter(SignUp));