// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBYtg6yKxto3MF9BRYDr4LFK_ZEwE8hRAE",
    authDomain: "preee-78b38.firebaseapp.com",
    projectId: "preee-78b38",
    storageBucket: "preee-78b38.appspot.com",
    messagingSenderId: "171125684182",
    appId: "1:171125684182:web:6b8c52c97c6407b0e15677",
    measurementId: "G-V0NT638RQ5"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

export const storage = getStorage(app);

export const db = getFirestore(app);

export const auth = getAuth(app);

export const messaging = getMessaging(app);